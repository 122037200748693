import * as React from "react";
import { observer } from "mobx-react";
import { withStores } from "stores";
import { AffinityLinkPilesProps } from "./typings";
import { LazyLoad } from "components/utility/LazyLoad/LazyLoad";
import { CrossLinkList as AffinityLinks } from "@shared-ui/retail-cross-links-link-list";
import { EgClickstreamData } from "@shared-ui/clickstream-analytics-context";
import { ViewLarge, ViewSmall, Viewport } from "@shared-ui/viewport-context";

export enum TargetType {
  BLANK = "_blank",
  SELF = "_self",
}

const TARGET_MAP: Record<string, TargetType> = {
  EXTERNAL: TargetType.BLANK,
  INTERNAL: TargetType.SELF,
};

const DEFAULT_TARGET = "INTERNAL";
const DEFAULT_COLUMNS_SMALL_VIEW = 1;

const impressionData: EgClickstreamData = {
  event: {
    event_name: "affinities_component.presented",
    event_type: "Impression",
    event_version: "1.0.0",
    event_category: "",
  },
  experience: {
    page_name: "Destination Landing",
  },
};

export const AffinityLinkPiles = withStores(
  "context",
  "uriContext"
)(
  observer((props: AffinityLinkPilesProps) => {
    const { context, templateComponent, uriContext: uriContextStore } = props;
    const { columns, targetType, limit } = templateComponent.config;
    const uriContext = uriContextStore?.get();
    if (!uriContext) {
      return null;
    }

    const inputs = {
      strategyName: "affinity-linkpiles",
      uriContext: uriContext as React.ComponentProps<typeof AffinityLinks>["inputs"]["uriContext"],
      parameters: [],
      limit: limit || 6,
    };

    const renderAffinityLinkPilesWithColumns = (col: number): React.JSX.Element => {
      return (
        <LazyLoad context={context}>
          <AffinityLinks
            inputs={inputs}
            columns={col}
            target={TARGET_MAP[targetType || DEFAULT_TARGET]}
            impressionData={impressionData}
            enableCache
          />
        </LazyLoad>
      );
    };

    return (
      <Viewport>
        <ViewSmall>{renderAffinityLinkPilesWithColumns(DEFAULT_COLUMNS_SMALL_VIEW)}</ViewSmall>
        <ViewLarge>{renderAffinityLinkPilesWithColumns(columns)}</ViewLarge>
      </Viewport>
    );
  })
);

export default AffinityLinkPiles;
